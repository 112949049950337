<template>
  <section>
    <validation-observer
      #default="{ invalid }"
      ref="refFormObserver"
    >
      <b-card
        header-tag="header"
        border-variant="info"
        header-bg-variant="light-info"
        header-class="py-50"
        class="border"
      >
        <template #header>
          <div class="d-flex align-items-center justify-content-between w-100">
            <h5 class="m-0">
              {{ $t('customer.tabInfo') }}
            </h5>

            <b-button
              v-if="readonly"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="py-75"
              :disabled="!canAccess('customer.updateCustomer')"
              @click="handleEdit"
            >
              <feather-icon
                icon="RefreshCwIcon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('customer.edit') }}</span>
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              class="py-75"
              :disabled="!canAccess('customer.updateCustomer')"
              @click="handleCancelEdit"
            >
              <feather-icon
                icon="XIcon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('customer.cancel') }}</span>
            </b-button>
          </div>
        </template>

        <b-card-body class="p-0">
          <!-- Form -->
          <b-form>
            <b-row class="mt-1">
              <!-- SECTION INFOR  -->
              <b-col cols="12">
                <!-- <div class="d-flex my-50">
                  <feather-icon
                    icon="InfoIcon"
                    size="19"
                  />
                  <h4 class="mb-0 ml-50">
                    {{ $t('customer.information') }}
                  </h4>
                </div> -->
                <b-row>
                  <!-- ANCHOR Last Name -->
                  <b-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Last Name"
                      rules="required"
                      immediate
                    >
                      <b-form-group
                        class="font-weight-bold"
                        :label="`${$t('customer.lastName')} ${isEditActive}`"
                        label-for="last-name"
                      >
                        <b-form-input
                          id="last-name"
                          v-model="customerToEdit.lastName"
                          :disabled="readonly"
                          :state="
                            getValidationState(validationContext) === false
                              ? false
                              : null
                          "
                          maxlength="30"
                          lazy-formatter
                          :formatter="trimInput"
                          :placeholder="$t('customer.placeholderLastName')"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR First Name -->
                  <b-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="First Name"
                      rules="required"
                      immediate
                    >
                      <b-form-group
                        class="font-weight-bold"
                        :label="`${$t('customer.firstName')} ${isEditActive}`"
                        label-for="first-name"
                      >
                        <b-form-input
                          id="first-name"
                          v-model="customerToEdit.firstName"
                          :disabled="readonly"
                          :state="
                            getValidationState(validationContext) === false
                              ? false
                              : null
                          "
                          maxlength="30"
                          lazy-formatter
                          :formatter="trimInput"
                          :placeholder="$t('customer.placeholderFirstName')"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR Email -->
                  <b-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Email"
                      :rules="`required|email|isUnique:customer,email,${customerData.emailAddress}`"
                      immediate
                    >
                      <b-form-group
                        class="font-weight-bold"
                        :label="`${$t('customer.email')} ${isEditActive}`"
                        label-for="email"
                      >
                        <b-form-input
                          id="email"
                          v-model="customerToEdit.emailAddress"
                          :disabled="readonly"
                          :state="
                            getValidationState(validationContext) === false
                              ? false
                              : null
                          "
                          :formatter="trimInput"
                          :placeholder="$t('customer.placeholderEmail')"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR Phone -->
                  <b-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Phone"
                      :rules="`required|phoneNumber|isUnique:customer,phonenumber,${customerData.phoneNumber}`"
                      immediate
                    >
                      <b-form-group
                        class="font-weight-bold"
                        :label="`${$t('customer.phoneNumber')} ${isEditActive}`"
                        label-for="phone"
                      >
                        <div class="d-flex">
                          <b-form-input
                            id="phone"
                            v-model="customerToEdit.phoneNumber"
                            v-remove-non-numeric-chars.allNumber
                            :disabled="readonly"
                            :state="
                              getValidationState(validationContext) === false
                                ? false
                                : null
                            "
                            :formatter="trimInput"
                            :debounce="300"
                            :placeholder="$t('customer.placeholderPhoneNumber')"
                          />
                          <!-- maxlength="10" -->
                          <b-button
                            v-if="!readonly"
                            variant="outline-info"
                            class="ml-50 px-1 text-nowrap"
                            :disabled="
                              !isActiveAddMorePhone ||
                                getValidationState(validationContext) === false
                            "
                            @click="addMorePhoneHandle"
                          >
                            <feather-icon icon="PlusIcon" />
                          </b-button>
                        </div>

                        <b-form-invalid-feedback
                          :state="
                            getValidationState(validationContext) === false
                              ? false
                              : null
                          "
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- ANCHOR Date Of Birth -->
                  <b-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="dob"
                      rules=""
                      immediate
                    >
                      <b-form-group
                        class="font-weight-bold"
                        :label="`${$t('customer.dob')}`"
                        label-for="dob"
                      >
                        <b-form-datepicker
                          v-model="customerToEdit.birthDay"
                          :disabled="readonly"
                          name="dob"
                          class="form-control"
                          :placeholder="$t('customer.placeholderDoB')"
                          :state="
                            getValidationState(validationContext) === false
                              ? false
                              : null
                          "
                          :date-format-options="{
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          }"
                          :max="new Date()"
                          :min="new Date(1920, 0, 1)"
                          show-decade-nav
                          hide-header
                          locale="vi"
                          no-flip
                        />
                      </b-form-group>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR Gender -->
                  <b-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Gender"
                      rules="required"
                      immediate
                    >
                      <b-form-group
                        class="font-weight-bold"
                        :label="`${$t('customer.gender')} ${isEditActive}`"
                        label-for="gender-type"
                      >
                        <v-select
                          v-model="customerToEdit.gender"
                          label="label"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="genderOptions"
                          :reduce="(val) => val.value"
                          :clearable="false"
                          :disabled="readonly"
                          input-id="gender-type"
                        >
                          <template #option="{value}">
                            <span>
                              {{ $t(value) }}
                            </span>
                          </template>

                          <template #selected-option="{value}">
                            <span>
                              {{ $t(value) }}
                            </span>
                          </template>

                          <template #no-options>
                            {{ $t('customer.noOptions') }}
                          </template>
                        </v-select>
                        <b-form-invalid-feedback
                          :state="
                            getValidationState(validationContext) === false
                              ? false
                              : null
                          "
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR backup Phone -->
                  <b-col
                    v-for="(phone, index) in customerToEdit.backupPhoneNumbers"
                    :key="index"
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Backup Phone"
                      :rules="`required|phoneNumber|isUnique:customer,phonenumber|duplicatePhone:${
                        customerToEdit.phoneNumber
                      },${customerToEdit.backupPhoneNumbers.toString()}`"
                      immediate
                    >
                      <b-form-group
                        class="font-weight-bold"
                        :label="`${$t('customer.backupPhoneNumber')} ${index + 1} ${isEditActive}`"
                        :label-for="`backup-phone-${index}`"
                      >
                        <div class="d-flex">
                          <b-form-input
                            :id="`backup-phone-${index}`"
                            v-model="customerToEdit.backupPhoneNumbers[index]"
                            v-remove-non-numeric-chars.allNumber
                            :disabled="readonly"
                            :state="
                              getValidationState(validationContext) === false
                                ? false
                                : null
                            "
                            :formatter="trimInput"
                            :debounce="300"
                            :placeholder="$t('customer.placeholderPhoneNumber')"
                          />
                          <!-- maxlength="10" -->
                          <b-button
                            v-if="!readonly"
                            variant="flat-danger"
                            class="ml-50 px-50 text-nowrap"
                            @click="removePhoneHandle(index)"
                          >
                            <feather-icon icon="XIcon" />
                          </b-button>
                        </div>
                        <b-form-invalid-feedback
                          :state="
                            getValidationState(validationContext) === false
                              ? false
                              : null
                          "
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <!-- ANCHOR Description -->
              <b-col
                cols="6"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Description"
                  rules=""
                >
                  <b-form-group
                    class="font-weight-bold"
                    :label="`${$t('customer.description')}`"
                    label-for="description"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="customerToEdit.description"
                      :disabled="readonly"
                      :placeholder="$t('customer.placeholderDescription')"
                      :rows="
                        ['xs', 'sm'].includes(
                          $store.getters['app/currentBreakPoint'],
                        )
                          ? 1
                          : 2
                      "
                      max-rows="4"
                      :state="
                        getValidationState(validationContext) === false
                          ? false
                          : null
                      "
                      class="overflow-auto"
                      lazy-formatter
                      :formatter="trimInput"
                      no-resize
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                cols="6"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Address Street"
                  rules=""
                  immediate
                >
                  <b-form-group
                    class="font-weight-bold"
                    :label="$t('customer.street')"
                    label-for="addressStreet"
                  >
                    <b-form-textarea
                      id="addressStreet"
                      v-model="customerToEdit.addressStreet"
                      :placeholder="$t('customer.placeholderStreet')"
                      :rows="
                        ['xs', 'sm'].includes(
                          $store.getters['app/currentBreakPoint'],
                        )
                          ? 1
                          : 2
                      "
                      max-rows="4"
                      class="overflow-auto"
                      lazy-formatter
                      :formatter="trimInput"
                      no-resize
                      :disabled="readonly"
                      :state="
                        getValidationState(validationContext) === false
                          ? false
                          : null
                      "
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- !SECTION -->

              <!-- SECTION ADDRESS  -->
              <b-col cols="12">
                <!-- <div class="d-flex my-50">
                  <feather-icon
                    icon="MapPinIcon"
                    size="19"
                  />
                  <h4 class="mb-0 ml-50">
                    {{ $t('customer.address') }}
                  </h4>
                </div> -->
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-row>
                      <!--ANCHOR addressCountry -->
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <validation-provider
                          #default="validationContext"
                          name="Address Country"
                          rules=""
                          immediate
                        >
                          <b-form-group
                            class="font-weight-bold"
                            :label="$t('customer.country')"
                            label-for="addressCountry"
                          >
                            <v-select
                              v-model="customerToEdit.addressCountry"
                              label="name"
                              :placeholder="$t('customer.placeholderCountry')"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :disabled="readonly"
                              :options="countryOptions"
                              @open="handleFetchCountry"
                            >
                              <template
                                v-slot:no-options="{ search, searching }"
                              >
                                <template v-if="searching">
                                  {{ $t('customer.noResultFund') }} <b>{{ search }}</b>
                                </template>
                                <template v-else-if="isLoadingCountry">
                                  <b-spinner
                                    variant="primary"
                                    label="Text Centered"
                                    small
                                    tag="span"
                                  />
                                  <span class="pl-1">
                                    {{ $t('customer.loadingCountryText') }}
                                  </span>
                                </template>
                              </template>
                            </v-select>
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- ANCHOR addressCity -->
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <validation-provider
                          #default="validationContext"
                          name="Address City"
                          rules=""
                          immediate
                        >
                          <b-form-group
                            class="font-weight-bold"
                            :label="$t('customer.city')"
                            label-for="addressCity"
                          >
                            <v-select
                              v-model="customerToEdit.addressCity"
                              label="name"
                              :placeholder="$t('customer.placeholderCity')"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :disabled="readonly"
                              :options="cityOptions"
                            >
                              <template
                                v-slot:no-options="{ search, searching }"
                              >
                                <template v-if="searching">
                                  {{ $t('customer.noResultFund') }} <b>{{ search }}</b>
                                </template>
                                <template v-else-if="isLoadingCity">
                                  <b-spinner
                                    variant="primary"
                                    label="Text Centered"
                                    small
                                    tag="span"
                                  />
                                  <span class="pl-1">
                                    {{ $t('customer.loadingCityText') }}
                                  </span>
                                </template>
                                <template v-else>
                                  <span>{{ $t('customer.needSelect_1') }}
                                    <b>{{ $t('customer.country') }}</b>
                                    {{ $t('customer.needSelect_2') }}</span>
                                </template>
                              </template>
                              <template v-slot:option="{ preName, name }">
                                <div class="d-flex align-items-center">
                                  <span class="d-block text-nowrap">
                                    {{ preName }} {{ name }}
                                  </span>
                                </div>
                              </template>
                            </v-select>

                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- ANCHOR address District -->
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <validation-provider
                          #default="validationContext"
                          name="Address District"
                          rules=""
                          immediate
                        >
                          <b-form-group
                            class="font-weight-bold"
                            :label="$t('customer.district')"
                            label-for="addressDistrict"
                          >
                            <v-select
                              v-model="customerToEdit.addressDistrict"
                              label="name"
                              :placeholder="$t('customer.placeholderDistrict')"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :disabled="readonly"
                              :options="districtOptions"
                            >
                              <template
                                v-slot:no-options="{ search, searching }"
                              >
                                <template v-if="searching">
                                  {{ $t('customer.noResultFund') }} <b>{{ search }}</b>
                                </template>
                                <template v-else-if="isLoadingDistrict">
                                  <b-spinner
                                    variant="primary"
                                    label="Text Centered"
                                    small
                                    tag="span"
                                  />
                                  <span class="pl-1">
                                    {{ $t('customer.loadingDistrictText') }}
                                  </span>
                                </template>
                                <template v-else>
                                  <span>{{ $t('customer.needSelect_1') }}
                                    <b>{{ $t('customer.city') }}</b>
                                    {{ $t('customer.needSelect_2') }}</span>
                                </template>
                              </template>
                              <template #selected-option="{ preName, name }">
                                <div class="d-flex align-items-center">
                                  <span class="d-block text-nowrap">
                                    {{ preName }} {{ name }}
                                  </span>
                                </div>
                              </template>
                              <template v-slot:option="{ preName, name }">
                                <div class="d-flex align-items-center">
                                  <span class="d-block text-nowrap">
                                    {{ preName }} {{ name }}
                                  </span>
                                </div>
                              </template>
                            </v-select>

                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- ANCHOR address Ward -->
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <validation-provider
                          #default="validationContext"
                          name="Address Ward"
                          rules=""
                          immediate
                        >
                          <b-form-group
                            class="font-weight-bold"
                            :label="$t('customer.ward')"
                            label-for="addressWard"
                          >
                            <v-select
                              v-model="customerToEdit.addressWard"
                              label="name"
                              :placeholder="$t('customer.placeholderWard')"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :disabled="readonly"
                              :options="wardOptions"
                            >
                              <template
                                v-slot:no-options="{ search, searching }"
                              >
                                <template v-if="searching">
                                  {{ $t('customer.noResultFund') }} <b>{{ search }}</b>
                                </template>
                                <template v-else-if="isLoadingWard">
                                  <b-spinner
                                    variant="primary"
                                    label="Text Centered"
                                    small
                                    tag="span"
                                  />
                                  <span class="pl-1">
                                    {{ $t('customer.loadingWardText') }}
                                  </span>
                                </template>
                                <template v-else>
                                  <span>{{ $t('customer.needSelect_1') }}
                                    <b>{{ $t('customer.district') }}</b>
                                    {{ $t('customer.needSelect_2') }}</span>
                                </template>
                              </template>
                              <template #selected-option="{ preName, name }">
                                <div class="d-flex align-items-center">
                                  <span class="d-block text-nowrap">
                                    {{ preName }} {{ name }}
                                  </span>
                                </div>
                              </template>
                              <template v-slot:option="{ preName, name }">
                                <div class="d-flex align-items-center">
                                  <span class="d-block text-nowrap">
                                    {{ preName }} {{ name }}
                                  </span>
                                </div>
                              </template>
                            </v-select>
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <!-- !SECTION -->
            </b-row>
          </b-form>
        </b-card-body>
      </b-card>

      <!-- ANCHOR - Mạng xã hội -->
      <b-card
        header-tag="header"
        border-variant="info"
        header-bg-variant="light-info"
        header-class="py-50 mb-1"
        class="border my-1"
      >
        <template #header>
          <div class="d-flex align-items-center justify-content-between w-100">
            <h6 class="text-body m-0">
              Mạng xã hội
            </h6>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary.window
              title="Chỉnh sửa"
              size="sm"
              variant="outline-info"
              class="btn-icon mr-1 py-25 px-50 d-flex-center"
              @click="editSocialNetwork(customerToEdit)"
            >
              <img src="@icons/edit.svg">
              <span
                v-if="!isMobileView"
                class="font-medium-2 ml-25"
                style="margin-top: 1px;"
              >
                Thay đổi mạng xã hội
              </span>
            </b-button>
          </div>
        </template>

        <div v-if="customerToEdit.socialAccounts">
          <div
            v-for="(item, i) in customerToEdit.socialAccounts"
            :key="i"
            class="mb-50 d-flex align-items-center"
          >
            <span
              class="mr-50 font-weight-bolder"
              style="min-width: 120px"
            >
              {{ item.type }}
            </span>

            <b-link
              class="url-ellipsis"
              :href="item.url"
              target="_blank"
            >
              {{ item.url }}
            </b-link>
          </div>
        </div>

        <div v-else>
          Chưa thêm mạng xã hội
        </div>
      </b-card>

      <!--  ANCHOR Button Actions -->
      <div class="d-flex justify-content-center">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-danger"
          :class="!readonly ? 'mr-50' : ''"
          @click="$emit('back')"
        >
          <feather-icon
            icon="RotateCcwIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('customer.back') }}</span>
        </b-button>
        <b-button
          v-if="!readonly"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="ml-50"
          :disabled="readonly || invalid || !canAccess('customer.updateCustomer')"
          @click="updateCustomerHandle(false)"
        >
          <feather-icon
            icon="RefreshCwIcon"
            class="mr-50"
          />
          <span class="align-middle"> {{ $t('customer.save') }} </span>
        </b-button>
      </div>
    </validation-observer>

    <ModalAddSocialNetwork
      v-if="customerToEdit"
      :customer-to-edit="customerToEdit"
      :social-accounts.sync="customerToEdit.socialAccounts"
      :customer-data="customerData"
    />
  </section>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BLink,
  BCard,
  BCardBody,
  BFormInvalidFeedback,
  BFormDatepicker,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import { ref, watch, computed } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import router from '@/router'
import { genderOptions } from '@/constants/selectOptions'

import { getDifference } from '@core/utils/utils'
import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput } from '@core/comp-functions/forms/formatter-input'

import useCustomerHandle from '@customer/useCustomerHandle'

import {
  required, email, min, dateFormat, isUnique,
} from '@validations'

import ModalAddSocialNetwork from './ModalAddSocialNetwork.vue'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
    BForm,
    BCard,
    BCardBody,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    BSpinner,
    BLink,
    vSelect,

    ModalAddSocialNetwork,
  },
  props: {
    customerData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const customerToEdit = ref(JSON.parse(JSON.stringify(props.customerData)))
    const resetCustomerToEdit = () => {
      customerToEdit.value = JSON.parse(JSON.stringify(props.customerData))
    }
    const { refFormObserver, getValidationState, resetForm } = formValidation(resetCustomerToEdit)
    const { updateCustomer, fetchCountries, fetchAdministrativeUnits } = useCustomerHandle()

    const readonly = ref(true)

    const isLoadingCountry = ref(false)
    const isLoadingCity = ref(false)
    const isLoadingDistrict = ref(false)
    const isLoadingWard = ref(false)

    const countryOptions = ref([])
    const cityOptions = ref([])
    const districtOptions = ref([])
    const wardOptions = ref([])

    const selectedIdCountry = ref(customerToEdit.value.addressCountry?.id)
    const selectedIdCity = ref(customerToEdit.value.addressCity?.id)
    const selectedIdDistrict = ref(customerToEdit.value.addressDistrict?.id)
    const selectedIdWard = ref(customerToEdit.value.addressWard?.id)

    const isReset = ref(false)

    const isEditActive = computed(() => {
      const val = readonly.value === true ? '' : '(*)'
      return val
    })

    if (selectedIdCountry.value) {
      fetchAdministrativeUnits({ parentId: selectedIdCountry.value })
        .then(res => {
          cityOptions.value = res.data.items
        })
        .catch(err => {
          console.error(err)
        })
    }
    if (selectedIdCity.value) {
      fetchAdministrativeUnits({ parentId: selectedIdCity.value })
        .then(res => {
          districtOptions.value = res.data.items
        })
        .catch(err => {
          console.error(err)
        })
    }
    if (selectedIdDistrict.value) {
      fetchAdministrativeUnits({ parentId: selectedIdDistrict.value })
        .then(res => {
          wardOptions.value = res.data.items
        })
        .catch(err => {
          console.error(err)
        })
    }

    function updateCustomerHandle(next = false) {
      const dataToUpdate = getDifference(
        customerToEdit.value,
        props.customerData,
      )
      updateCustomer(customerToEdit.value, dataToUpdate, () => {
        emit('refetch-data')
        if (next) {
          router.push({ name: 'apps-customers-list' })
        } else {
          router.push({ name: 'apps-customers-list' })
          emit('refetch-data')
          readonly.value = true
        }
      })
    }

    const onCancel = () => {
      resetForm()
      router.go(-1)
    }

    function handleEdit() {
      readonly.value = false
      isReset.value = false
    }

    function handleCancelEdit() {
      resetForm()
      isReset.value = true
      readonly.value = true
    }

    function backHandle() {
      resetForm()
      this.$router.go(-1)
    }

    // Address
    async function handleFetchCountry() {
      try {
        isLoadingCountry.value = true
        const data = await fetchCountries()
        if (data) {
          countryOptions.value = data.data.items
        }
      } catch (error) {
        console.error(error)
      } finally {
        isLoadingCountry.value = false
      }
    }

    // Watch country
    watch(
      () => customerToEdit.value.addressCountry,
      newValue => {
        if (!newValue) {
          cityOptions.value = []
          customerToEdit.value.addressCity = null
        } else {
          isLoadingCity.value = true
          fetchAdministrativeUnits({ parentId: newValue.id })
            .then(res => {
              cityOptions.value = res.data.items
            })
            .catch(err => {
              console.error(err)
            })
            .finally(() => {
              isLoadingCity.value = false
            })
          if (newValue && isReset.value) return
          customerToEdit.value.addressCity = null
        }
      },
    )

    // Watch City
    watch(
      () => customerToEdit.value.addressCity,
      newValue => {
        if (!newValue) {
          districtOptions.value = []
          customerToEdit.value.addressDistrict = null
        } else {
          isLoadingDistrict.value = true
          fetchAdministrativeUnits({ parentId: newValue.id })
            .then(res => {
              districtOptions.value = res.data.items
            })
            .catch(err => {
              console.error(err)
            })
            .finally(() => {
              isLoadingDistrict.value = false
            })
          if (newValue && isReset.value) return
          customerToEdit.value.addressDistrict = null
        }
      },
    )

    // Watch District
    watch(
      () => customerToEdit.value.addressDistrict,
      newValue => {
        if (!newValue) {
          wardOptions.value = []
          customerToEdit.value.addressWard = null
        } else {
          isLoadingWard.value = true
          fetchAdministrativeUnits({ parentId: newValue.id })
            .then(res => {
              wardOptions.value = res.data.items
            })
            .catch(err => {
              console.error(err)
            })
            .finally(() => {
              isLoadingWard.value = false
            })
          if (newValue && isReset.value) return
          customerToEdit.value.addressWard = null
        }
      },
    )

    const isActiveAddMorePhone = ref(true)

    // Watch isActive backupPhone
    watch(
      () => [...customerToEdit.value.backupPhoneNumbers],
      () => {
        const backupLength = customerToEdit.value.backupPhoneNumbers.length
        if (backupLength === 0) isActiveAddMorePhone.value = true
        else if (backupLength >= 4) isActiveAddMorePhone.value = false
        else {
          isActiveAddMorePhone.value = true
          customerToEdit.value.backupPhoneNumbers.forEach(
            async backupPhone => {
              const { valid } = await validate(
                backupPhone,
                `required|phoneNumber|isUnique:customer,phonenumber|duplicatePhone:${
                  customerToEdit.value.phoneNumber
                },${customerToEdit.value.backupPhoneNumbers.toString()}`,
              )
              if (!valid) isActiveAddMorePhone.value = false
            },
          )
        }
      },
    )

    function addMorePhoneHandle() {
      customerToEdit.value.backupPhoneNumbers.push('')
    }

    function removePhoneHandle(index) {
      customerToEdit.value.backupPhoneNumbers.splice(index, 1)
    }

    async function editSocialNetwork(data) {
      await new Promise(resolve => {
        if (!data) return

        resolve()
      })

      this.$bvModal.show('modal-customer-add-social-network')
    }

    return {
      customerToEdit,
      updateCustomerHandle,
      onCancel,
      handleEdit,
      handleCancelEdit,
      backHandle,
      refFormObserver,
      readonly,
      genderOptions,

      getValidationState,
      required,
      email,
      min,
      dateFormat,
      isUnique,

      // Address
      fetchAdministrativeUnits,
      fetchCountries,
      countryOptions,
      cityOptions,
      districtOptions,
      wardOptions,
      handleFetchCountry,
      isLoadingCountry,
      isLoadingCity,
      isLoadingDistrict,
      isLoadingWard,
      selectedIdCountry,
      selectedIdCity,
      selectedIdDistrict,
      selectedIdWard,
      isReset,

      isEditActive,

      isActiveAddMorePhone,
      addMorePhoneHandle,
      removePhoneHandle,

      trimInput,
      editSocialNetwork,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
